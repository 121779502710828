<template>
  <div class="coupon_out">
    <div class="c_inner">
      <div class="ci_header t_a p_r">
        <span>可使用的优惠券</span>
        <img src="../assets/img/ci_close.png" alt="" class="p_a" @click="$emit('close')">
      </div>
      <div class="ci_con">
        <div class="ci_items d_f ali_c" v-for="(item,index) in couponArr" @click="acIndex = index" :key="index">
          <div class="check">
            <img src="../assets/img/ci_check.png" alt="" v-if="acIndex == index">
            <img src="../assets/img/ci_check_dis.png" alt="" v-else>
          </div>
          <div class="ci_pri">￥<span>{{item.minUseAmount/100}}</span></div>
          <div class="t_a flex_1">
            <div>{{item.couponName}}</div>
            <div class="date">有效期：<span>{{item.useBeginDate}}</span>   至   {{item.useEndDate}}</div>
          </div>
          <div class="ci_sta">可使用</div>
        </div>
      </div>
      <div class="ci_btn" @click="chooseCoupon">确定</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "couponList",
    props:['couponArr'],
    data(){
      return{
        acIndex:null,
      }
    },
    methods:{
      chooseCoupon(){
        this.$emit('chooseCoupon',this.couponArr[this.acIndex])
      }
    }
  }
</script>

<style scoped lang="scss">
  .coupon_out{
    .ci_btn{
      height: 44px;
      background: #3FD467;
      line-height: 44px;
      text-align: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
    }
    .ci_con{
      .ci_items{
        width: 100%;
        .ci_sta{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          text-align: right;
        }
        .t_a{
          .date{
            margin-top: 6px;
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #B2B2B2;
          }
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .ci_pri{
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF3E6E;
          width: 80px;
        }
        .check{
          img{
            width: 18px;
            height: 18px;
          }
          margin-right: 15px;
        }
        border-bottom: 1PX solid #E6E6E6;
        height: 58px;
      }
      padding: 0 10px 10px;
      min-height: 200px;
      max-height: 500px;
      overflow-y: auto;
    }
    .c_inner{
      .ci_header{
        height: 42px;
        line-height: 42px;
        border-bottom: 1PX solid #B5B5B5;
        img{
          width: 13px;
          height: 13px;
          right: 9px;
          top: 12px;
        }
      }
      background: #FFFFFF;
      border-radius: 10px 10px 0px 0px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.3);
  }
</style>
